import React, { useEffect, useState } from 'react';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import { Link } from 'react-router-dom';
import { useShortUrl } from "../../utils/basePlaycentreUrl";
import * as Icon from 'react-bootstrap-icons';

export default function MemberList() {
  const baseUrl = useShortUrl();

  const [loading, setLoading] = useState(true);

  let [memberList, setMemberList] = useState([]);
  let [permissions, setPermissions] = useState(null);

  useEffect(() => {
    const loadMembers = () => {
      api
        .get(`${baseUrl}/members/list`)
        .then((response) => {
          setMemberList(response.data.members);          
          setPermissions(response.data.currentPcPermissions);
          setLoading(false);
        });
    };
    loadMembers();    

  }, [baseUrl]);


  if (loading)
    return <Loading />;

  return (
    <>
    
      <div className="row">        
        <div className="col text-center">
          <h3>All Playcentre Members</h3>
        </div>
      </div>
      <div className="row mt-1 mb-3">
        <div className="col">          
          {permissions?.canEditMembers &&
            <Link to={`${baseUrl}/settings/members/add`} className="btn btn-success float-end fw-bolder">Add a new member</Link>
          }
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr className="pb-3">
            <th>Member's name</th>
            <th className="fw-bold fst-italic">Children's name</th>
            <th className="fw-bold fst-italic">Age</th>
            <th className="fw-bold fst-italic">DOB</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {memberList.map((member) => (
            <React.Fragment key={member.uid}> 
              <tr>
                <td className="fw-bold">{member.displayName}</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{member.email}</td>
                <td>{member.phone}</td>
                <td>
                  {permissions?.canEditMembers &&
                    <Link to={`${baseUrl}/settings/members/${member.uid}/edit`} className="btn btn-link"><Icon.Pencil className="text-danger" size="1.2rem" /></Link>
                  }
                </td>
              </tr>
              
              {member.kids.map((kid) => (
                <tr key={kid.uid}>
                  <td></td>
                  <td className="fw-bold fst-italic">{kid.displayName}</td>
                  <td>{kid.ageFull}</td>
                  <td>{kid.dob}</td>
                  <td></td>
                  <td></td>
                  <td></td>

                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>

    </>
  );
}

