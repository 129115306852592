import { useEffect, useState } from 'react';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import { useFullUrl } from '../../utils/basePlaycentreUrl';
import * as Icon from 'react-bootstrap-icons';

import styles from './RosterEdit.module.scss';


const VISIT_TYPE_PLANNING_TEAM = 2;
const VISIT_SESSION_LEADER = 3;

const visitNames = [
  { name: '', value: 0 },
  { name: 'Regular', value: 1 },
  { name: 'Planning Team', value: VISIT_TYPE_PLANNING_TEAM },
  { name: 'Session Leader', value: VISIT_SESSION_LEADER },
  { name: 'No PT Session Leader', value: 6 },
  { name: 'Drop Off', value: 4 },
  { name: 'Regular with helper', value: 5 }
];

export default function RosterEdit() {
  const baseUrl = useFullUrl();

  const [loading, setLoading] = useState(true);

  let [term, setTerm] = useState();
  let [memberList, setMemberList] = useState([]);
  let [allOtherMemberList, setAllOtherMemberList] = useState([]);
  let [pc, setPc] = useState();

  useEffect(() => {
    const loadMembers = () => {
      api
        .get(`${baseUrl}/roster/edit`)
        .then((response) => {
          setTerm(response.data.term);
          setMemberList(response.data.members.map(x => { return { editMode: false, ...x }; }));
          setAllOtherMemberList(response.data.allOtherMembers.map(x => { return { editMode: false, ...x }; }));
          setPc(response.data.playcentre);
          setLoading(false);
        });
    };
    loadMembers();

  }, [baseUrl]);

  const checkIfPlanningTeam = (visitType) => visitType === VISIT_TYPE_PLANNING_TEAM || visitType === VISIT_SESSION_LEADER;
    
  if (loading)
    return <Loading />;


  const EditActionCell = ({ line }) => {
    const toggleEditMode = () => {
      const updatedMemberList = memberList.map((member) => {
        if (member.member.uid === line.member.uid) {
          return { ...member, editMode: !member.editMode };
        }
        return member;
      });
      setMemberList(updatedMemberList);
    };

    const onSaveLineClick = () => {
      api
        .post(`${baseUrl}/roster/save`, line)
        .then((response) => {          
          toggleEditMode();          
        });
    };

    const onDeleteLineClick = () => {
      api
        .post(`${baseUrl}/roster/delete`, line)
        .then((response) => {
          toggleEditMode();
          moveLineFromMainList(line.member.uid);
        });
    }

    return (
      <>        
        <td>
          {line.editMode && (
            <button className="btn btn-link ms-3" onClick={onDeleteLineClick}>
              <Icon.Trash className="text-danger" size="1.3rem" />
            </button>
          )}
        </td>

        <td>
          {!line.editMode && (
            <button className="btn btn-link" onClick={toggleEditMode}>
              <Icon.Pencil className="text-danger" size="1.2rem" />
            </button>
          )}
          {line.editMode && (
            <button className="btn btn-primary" onClick={onSaveLineClick}>
              Save
            </button>
          )}
        </td>   
      </>
    );
  };

  const VisitTypeDropDown = ({ value, onChange, showHelpers }) => {
    return (
      <select className="form-select" aria-label="Default select example" defaultValue={value} onChange={onChange}>
        {visitNames.filter(x => showHelpers || x.value !== 5).map((visit) => (
          <option key={visit.value} value={visit.value}>{visit.name}</option>
        ))}
      </select>
    )
  };

  const MemberHelperDropDown = ({ helpers, value, onChange }) => {
    return (
      <select className="form-select" aria-label="Default select example" defaultValue={value} onChange={onChange}>
        {helpers && helpers.length > 0 && helpers.map((helper) => (
          <option key={helper.uid} value={helper.uid}>{helper.displayName}</option>
        ))}
      </select>
    )
  };

  const EditVisitCell = ({ line, dayVisit, onChange }) => {
    const [showKids, setShowKids] = useState(dayVisit.visitType !== 0);
    const [checked, setChecked] = useState(dayVisit.attendingKids);
    const [showHelpers, setShowHelpers] = useState(dayVisit.visitType === 5);

    const setVisitType = (dayVisit, visitType) => {      
      if (!dayVisit)
        dayVisit = { visitType: 0 };

      dayVisit.visitType = parseInt(visitType);
      if (!showKids && dayVisit.visitType !== 0) { // changing from nothing to something
        dayVisit.attendingKids = line.kids.map(kid => kid.uid);
        setChecked(dayVisit.attendingKids);
      }

      setShowKids(dayVisit.visitType !== 0);
      setShowHelpers(dayVisit.visitType === 5);
      if (dayVisit.visitType === 5 && !dayVisit.memberHelperUid && line.helpers && line.helpers.length > 0) {
        dayVisit.memberHelperUid = line.helpers[0]?.uid;
      }
      console.log(dayVisit.memberHelperUid);
    };

    const handleCheck = (event) => {
      var updatedList = [...checked];
      if (event.target.checked) {
        updatedList = [...checked, event.target.value];
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      setChecked(updatedList);
      dayVisit.attendingKids = updatedList;
    };    

    return (
      <td className={`${checkIfPlanningTeam(dayVisit.visitType) ? styles.planningTeam : ''}`}>
        {!line.editMode && (
          fillCell(dayVisit)
        )}        
        {line.editMode && (
          <>
            <VisitTypeDropDown value={dayVisit.visitType} showHelpers={line.helpers && line.helpers.length > 0} onChange={e => setVisitType(dayVisit, parseInt(e.target.value))} /> 

            {showHelpers && ( 
              <MemberHelperDropDown helpers={line.helpers} value={dayVisit.memberHelperUid} onChange={e => { console.log(e.target.value); dayVisit.memberHelperUid = e.target.value }} />
            )}

            {showKids && line.kids.map((kid) => (
              <div key={kid.uid} className="form-check"> 
              {/* add 'id' for input and 'for' for label so the label will be clickable */}
                <input value={kid.uid} type="checkbox" className="form-check-input" onChange={handleCheck} checked={checked.includes(kid.uid, 0)} />
                <label className="form-check-label">{kid.displayName}</label>
              </div>
            ))}
          </>
        )}
      </td>
    );
  };

  const Line = ({ line, index }) => {
    var isPlanningTeam = checkIfPlanningTeam(line.monday.visitType) ||
      checkIfPlanningTeam(line.tuesday.visitType) ||
      checkIfPlanningTeam(line.wednesday.visitType) ||
      checkIfPlanningTeam(line.thursday.visitType) ||
      checkIfPlanningTeam(line.friday.visitType);

    var areNotKidsAttendingForTheDay = (day) => {      
      return day.visitType !== 0 && (day.attendingKids.length < line.kids.length);
    }
    var areNotKidsAttending = areNotKidsAttendingForTheDay(line.monday) ||
      areNotKidsAttendingForTheDay(line.tuesday) ||
      areNotKidsAttendingForTheDay(line.wednesday) ||
      areNotKidsAttendingForTheDay(line.thursday) ||
      areNotKidsAttendingForTheDay(line.friday);

    return (
      <tr className={`${isPlanningTeam ? '' : styles.noPlanningTeam} ${areNotKidsAttending ? styles.notAllKidsAttending : ''} ${line.editMode ? styles.editModeLine : ''}`}>
        <td>{index + 1}</td>
        <td>{line.member.displayName} ({line.kids.length})</td>
        <td>{line.member.rosterEducationName}</td>

        <EditVisitCell line={line} dayVisit={line.monday} />
        <EditVisitCell line={line} dayVisit={line.tuesday} />
        <EditVisitCell line={line} dayVisit={line.wednesday} />
        <EditVisitCell line={line} dayVisit={line.thursday} />
        <EditVisitCell line={line} dayVisit={line.friday} />
        <EditActionCell line={line} />        
      </tr>
    )
  };

  const moveLineToMainList = (uid) => {
    let lineToMove = allOtherMemberList.find(x => x.member.uid === uid);
    setMemberList([...memberList, lineToMove]);
    setAllOtherMemberList(allOtherMemberList.filter(x => x.member.uid !== uid));
  };

  const moveLineFromMainList = (uid) => {
    let lineToMove = memberList.find(x => x.member.uid === uid);
    setAllOtherMemberList([...allOtherMemberList, lineToMove]);
    setMemberList(memberList.filter(x => x.member.uid !== uid));
  };

  const AllOtherMembers = ({ members }) => {
    const [selectedMemberUid, setSelectedMemberUid] = useState(members.length > 0 ? members[0].member.uid : null);
    const onChange = (event) => {
      setSelectedMemberUid(event.target.value);
    };

    const addLine = (event) => {
      if (selectedMemberUid)
        moveLineToMainList(selectedMemberUid);
    };

    return (
      <>
        <td></td>
        <td>
          <select className="form-select" aria-label="Default select example" onChange={onChange} >
            {members.map((member) => (
              <option key={member.member.uid} value={member.member.uid}>{member.member.displayName}</option>
            ))}
          </select>
        </td>
        <td colSpan={7}></td>
        <td>
          <button className="btn btn-link" onClick={addLine}>
            <Icon.PlusCircle className="text-success" size="1.5rem" />
          </button>
        </td>
      </>
    )
  };

  const fillCell = (data) => {
    if (data.visitType !== 0) {
      let res = `${visitNames.find(x => x.value === data.visitType).name} (${data.attendingKids.length})`;
      return res;
    }

    return '';
  };

  return (
    <>
      <table className={`table ${styles.rosterEdit} ${styles.legend}`}>
        <thead>
          <tr>
            <th colSpan={5} className="text-center">Legend</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.planningTeam}>Session leader</td>
            <td className={styles.noPlanningTeam}>No planning team</td>
            <td className={styles.notAllKidsAttending}>Not all kids attending</td>
            <td>Member: (number of all kids)</td>
            <td>Roster visit: (number of attending kids)</td>
          </tr>
        </tbody>
      </table>

      <table className={`table table-striped ${styles.rosterEdit}`}>
        <thead>
          <tr>
            <th colSpan={9} className="text-center">
              <h3>Roster setup</h3>
              <h5>{pc.displayName}</h5>
              <h5>{term.displayName}, {term.termDates}</h5>
            </th>
          </tr>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Qualification</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {memberList.map((line, index) => (            
            <Line line={line} key={line.member.uid} index={index} />
          ))}

          <tr>
            <AllOtherMembers members={allOtherMemberList } />
          </tr>
        </tbody>
      </table>

    </>
  );
}

