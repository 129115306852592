import React, { useEffect, useState } from 'react';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import { useParams, useNavigate } from 'react-router-dom';
import { useShortUrl } from "../../utils/basePlaycentreUrl";

import { Formik, Field, Form, ErrorMessage, FieldArray, getIn, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./MemberEdit.scss";

export default function MemberEdit() {
  const baseUrl = useShortUrl();

  const { memberUid } = useParams();
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  let [data, setData] = useState();
  let [terms, setTerms] = useState([]);
  let [educations, setEducations] = useState([]);
  let [buddies, setBuddies] = useState([]);

  const endpointPath = memberUid ? `${baseUrl}/members/${memberUid}/edit` : `${baseUrl}/members/add`;
  const saveButtonText = memberUid ? `Save changes` : `Add a new member`;

  useEffect(() => {
    const loadMember = () => {
      api
        .get(endpointPath)
        .then((response) => {
          response.data.kids.map(kid => kid.dob = new Date(kid.dob));

          if (response.data.member.dueDate)
            response.data.member.dueDate = new Date(response.data.member.dueDate);

          if (response.data.member.firstAidExpiryDate)
            response.data.member.firstAidExpiryDate = new Date(response.data.member.firstAidExpiryDate);

          setData(response.data);
          setLoading(false);
        });
    };

    const loadTerms = () => {
      api
        .get(`/settings/terms/list`)
        .then((response) => {
          response.data.termList.sort((a, b) => a.year === b.year ? b.index - a.index : b.year - a.year);
          setTerms(response.data.termList);
        });        
    };

    const loadEducations = () => {
      api
        .get(`/settings/educations/list`)
        .then((response) => {
          response.data.educationList.sort((a, b) => b.sortOrder-a.sortOrder);
          setEducations(response.data.educationList);          
        });
    };

    const loadBuddies = () => {
      api
        .get(`${baseUrl}/settings/buddies/list`)
        .then((response) => {          
          setBuddies(response.data);
        });
    };

    new Promise((resolve, reject) => {
      loadTerms();
      resolve();
    }).then(() => {
      return new Promise((resolve, reject) => {
        loadEducations();
        resolve();
      });      
    }).then(() => {
      return new Promise((resolve, reject) => {
        loadBuddies();
        resolve();
      });
    }).then(() => {
      loadMember();
    });    

  }, [endpointPath, memberUid, baseUrl]);
  

  if (loading)
    return <Loading />;

  const TextFieldHalf = ({ id, title, objectPath, inputType }) => {
    return (
      <>
        <div className="form-group pb-3 col-sm-6">
          <label className="col-sm-12 col-form-label fw-bold" htmlFor={id}>{title}</label>
          <div className="col-sm-12">
            <Field name={objectPath} id={id} type={inputType ?? 'text'} className="form-control" />
            <ErrorMessage name={objectPath}>{msg => <div className="alert alert-danger">{msg}</div>}</ErrorMessage>
          </div>
        </div>
      </>
    )
  }

  const TextField = ({ id, title, objectPath, inputType}) => {
    return (
      <>
        <div className="form-group row pb-3">
          <label className="col-sm-2 col-form-label" htmlFor={id}>{title}</label>
          <div className="col-sm-10">
            <Field name={objectPath} id={id} type={inputType ?? 'text'} className="form-control" />
            <ErrorMessage name={objectPath}>{msg => <div className="alert alert-danger">{msg}</div>}</ErrorMessage>
          </div>
        </div>
      </>
    )
  }

  const SelectFieldBare = ({ id, objectPath, options, defaultOption }) => {
    return (
      <Field name={objectPath} id={id} as="select" className="form-control form-select">
        {defaultOption && <option value="00000000-0000-0000-0000-000000000000">{defaultOption}</option>}
        {options.map((option) => <option key={option.uid} value={option.uid}>{option.displayName}</option>)}
      </Field>
    )
  }

  const SelectFieldHalf = ({ id, title, objectPath, options, defaultOption }) => {
    return (
      <>
        <div className="form-group pb-3 col-sm-6">
          <label className="col-sm-12 col-form-label fw-bold" htmlFor={id}>{title}</label>
          <div className="col-sm-12">
            <SelectFieldBare id={id} objectPath={objectPath} options={options} defaultOption={defaultOption} />
          </div>
        </div>
      </>
    )
  }

  const SelectField = ({ id, title, objectPath, options, defaultOption }) => {
    return (
      <>
        <div className="form-group row pb-3">
          <label className="col-sm-2 col-form-label" htmlFor={id}>{title}</label>
          <div className="col-sm-10">
            <SelectFieldBare id={id} objectPath={objectPath} options={options} defaultOption={defaultOption} />            
          </div>
        </div>
      </>
    )
  }

  const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          setFieldValue(field.name, val);
        }}
      />
    );
  };

  const CalendarField = ({ id, title, objectPath, props, isHalf, comment }) => {
    let mainClass = isHalf ? 'col-sm-6' : 'row';
    let labelClass = isHalf ? 'col-sm-12' : 'col-sm-2';
    let divClass = isHalf ? 'col-sm-12' : 'col-sm-10';
    return (
      <>
        <div className={`form-group ${mainClass} pb-3`}>
          <label className={`${labelClass} col-form-label  fw-bold`} htmlFor={id}>{title}</label>
          <div className={`${divClass}`}>
            <DatePickerField 
              name={objectPath}
              className="form-control"
              dateFormat="dd-MMM-yyyy"
              showYearDropdown
              showIcon    
              isClearable
              selected={getIn(props.values, objectPath) || ''}
              //  value={values.config[0].date}
              onChange={e => props.setFieldValue(objectPath, e)}
            />

          </div>
          {comment && <small className="text-body-secondary">{comment}</small>}
        </div>
      </>
    )
  }

  const Box = ({ header, children }) => {
    return (
      <div className="accordion-item">
        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
          <div className="accordion-button" type="div" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
            {header}
          </div>
        </h2>
        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
          <div className="accordion-body">
            {children}
          </div>
        </div>
      </div>  
    );
  };
  
  return (
    <>
      <h2>{memberUid ? `Edit '${data?.member?.firstName} ${data?.member?.lastName}' details` : 'Add a new member'}</h2>
      <Formik
        initialValues={{ data: data }}
        validationSchema={Yup.object().shape({
          data: Yup.object().shape({
            member: Yup.object().shape({
              firstName: Yup.string().required('Required'),
              lastName: Yup.string().required('Required'),
            }),
            kids: Yup.array().of(
              Yup.object().shape({
                firstName: Yup.string().required('Required'),
                lastName: Yup.string().required('Required'),
                dob: Yup.date().required('Required').min(new Date(1900, 0, 1), "Really?"),
              })
            )
            //.required('Must have kids')
          })
          //lastName: Yup.string()
          //  .max(20, 'Must be 20 characters or less')
          //  .required('Required'),
          //email: Yup.string().email('Invalid email address').required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          api.post(endpointPath, values.data)
            .then(() => navigate(`${baseUrl}/settings/members/list`));

          setTimeout(() => {
            console.log(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >{(props) =>
          <Form>
          <div className="accordion">
            <Box header="Personal Details">
              <div className="row">
                <TextFieldHalf title="First Name" objectPath="data.member.firstName" id="firstName" />
                <TextFieldHalf title="Last Name" objectPath="data.member.lastName" id="lastName" />
              </div>
              <div className="row">
                <TextFieldHalf title="Email" objectPath="data.member.email" id="email" inputType="email" />
                <TextFieldHalf title="Phone number" objectPath="data.member.phone" id="phone" />
              </div>

              <div className="row">
                <TextFieldHalf title="Job title" objectPath="data.member.jobTitle" id="phone" />
              </div>
            </Box>

            <Box header="Term details">
              <div className="row">
                <SelectFieldHalf title="First term" objectPath="data.member.firstTermUid" id="firstTerm" options={terms} />
                <SelectFieldHalf title="First term buddy" objectPath="data.member.buddyMemberUid" id="buddyMemberUid" options={buddies} defaultOption="Not selected" />
              </div>
              <div className="row">
                <SelectFieldHalf title="Last term" objectPath="data.member.lastTermUid" id="lastTerm" options={terms} defaultOption="Not selected" />
              </div>
            </Box>
            <Box header="Additional Details">
              <SelectFieldHalf title="Education" objectPath="data.member.educationUid" id="education" options={educations} />
                <CalendarField title="First aid expiry date (if any)" objectPath="data.member.firstAidExpiryDate"
                  id="firstAidExpiryDate" props={props} isHalf={true} comment="If a member has a valid FA certificate"/>
                <CalendarField title="Due date (if any)" objectPath="data.member.dueDate"
                  id="dueDate" props={props} isHalf={true} comment="Approximate date if a member is pregnant - this is used to highlight them in the roster"/>
            </Box>                                 

          <Box header="Children">

            <div className="row">
                <div className="col  fw-bold">First name</div>
                <div className="col fw-bold">Last name</div>
                <div className="col fw-bold">Date of birth</div>
                <div className="col fw-bold">Last term</div>
              <div className="col"></div>
            </div>
            <FieldArray
              name="data.kids"             
                render={arrayHelpers => (
                <>
                  <div>
                    {props.values.data.kids.map((kid, index) => (
                      <div key={index}>
                        <div className="row pb-3">
                          <div className="col">
                            <Field name={`data.kids[${index}].firstName`} className="form-control" />
                            <ErrorMessage name={`data.kids[${index}].firstName`}>{msg => <div className="alert alert-danger">{msg}</div>}</ErrorMessage>
                          </div>
                          <div className="col">
                            <Field name={`data.kids[${index}].lastName`} className="form-control" />
                            <ErrorMessage name={`data.kids[${index}].lastName`}>{msg => <div className="alert alert-danger">{msg}</div>}</ErrorMessage>
                          </div>
                          <div className="col">
                            <DatePicker
                              className="form-control"                            
                              dateFormat="dd-MMM-yyyy"
                              showYearDropdown
                              showIcon
                              name={`data.kids[${index}].['dob']`}
                              // selected={getIn(values,`config[${index}].date`)}
                              selected={getIn(props.values, `data.kids[${index}]['dob']`) || ''}
                              //  value={values.config[0].date}
                              onChange={e => props.setFieldValue(`data.kids[${index}]['dob']`, e)}                           
                            />    
                            <ErrorMessage name={`data.kids[${index}].dob`}>{msg => <div className="alert alert-danger">{msg}</div>}</ErrorMessage>
                          </div>
                          <div className="col">
                            <SelectFieldBare objectPath={`data.kids[${index}].lastTermUid`} options={terms} defaultOption="Not selected" />                       
                          </div>
                          <div className="col">
                            {kid.canDelete && <button type="button" className="btn btn-warning" onClick={() => arrayHelpers.remove(index)}> Remove </button> }
                          </div>
                        </div>
                      </div>
                    ))}
                    </div>
                    <div className="row">
                      <div className="col">
                        <button
                          type="button" className="btn btn-success float-end"
                          onClick={() => { console.log(props); arrayHelpers.push({ firstName: '', lastName: '', dob: null, canDelete: true }) }}
                        > Add a new child </button>
                      </div>
                    </div>
                </>
              )}
            />
            </Box>
          </div>

          <div className="d-grid gap-2 col-6 mx-auto mt-5 mb-5 pb-5">
            <button className="btn btn-primary fw-bold" type="submit">{saveButtonText}</button>
          </div>           
         <div className="pt-3"></div>
        </Form>
        }
      </Formik>

    </>
  );
}

