import React, { useEffect, useState } from 'react';
import { Nav, Collapse, Navbar, NavbarBrand, NavbarToggler, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Button, NavLink, NavItem } from 'reactstrap';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import styles from './Nav.module.scss';
import api from '../../services/api'
import fileDownload from '../../services/fileDownload'
import * as CommonIcons from '../CommonIcons';
import AuthService from "../../services/auth.service";
import * as Icon from 'react-bootstrap-icons';



export default function NavMenu() {
  const { termYear, termIndex, pcId } = useParams();  
  const navigate = useNavigate();  
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(true);
  const [terms, setTerms] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState({year: 0, index: 0});

  const [playcentres, setPlaycentres] = useState(null);
  const [selectedPc, setSelectedPc] = useState(null);

  const [urlPrefix, setUrlPrefix] = useState(null);

  useEffect(() => {
    const loadTerms = () => {
      api
        .get(`/settings/terms/list`)
        .then((response) => {
          let list = response.data.termList;
          setTerms(list);

          if (!location.pathname.startsWith('/settings')) {
            if (!termYear || !termIndex || (Number(termYear) === 0 && Number(termIndex) === 0)) {
              if (response.data.currentTermUid && response.data.currentTermUid !== '') {
                let term = list.find(t => t.uid === response.data.currentTermUid);
                onTermClick(term);
              } else {
                onTermClick(list[0]);
              }
              //navigate(`/term/${list[0].year}/${list[0].index}/`);
            } else {
              let term = list.find(t => t.year === Number(termYear) && t.index === Number(termIndex));
              //setSelectedTerm(term);
              onTermClick(term);
            }
          }
        })
        .catch((err) => {
          console.log('trying to load terms', err);
          setTimeout(() => { loadTerms(); }, 5000);
        });;
    }

    const loadPlaycentres = () => {
      api
        .get(`/settings/playcentres/list`)
        .then((response) => {
          let list = response.data.playcentreList;
          setPlaycentres(list);

          let pcFound = false;
          if (pcId) {
            let activePc = list.find(x => x.urlId === pcId);
            if (activePc) {
              setSelectedPc(activePc);
              pcFound = true;
            }
          }

          if (!pcFound && response.data.currentPlaycentreUid) {
            let activePc = list.find(x => x.uid === response.data.currentPlaycentreUid);
            if (activePc)
              setSelectedPc(activePc);              
          }
        })
        .catch((err) => {
          console.log('trying to load playcentres', err);
          setTimeout(() => { loadTerms(); }, 5000);
        });;
    }

    loadTerms();
    loadPlaycentres();

  }, []);

  useEffect(() => {
    if (selectedPc && selectedTerm && selectedTerm.year !== 0) {
      setUrlPrefix(`/pc/${selectedPc.urlId}/term/${selectedTerm.year}/${selectedTerm.index}`);
    }
  }, [selectedPc, selectedTerm]);

  useEffect(() => {    

    if (!selectedPc || !selectedTerm || selectedTerm.year === 0)
      return;

    if (location.pathname === '/') {
      navigate(urlPrefix);
    } else {
      if (location.pathname.startsWith('/pc')) {
        let match = location.pathname.match(/\/pc\/[^/]+\/term\/\d+\/\d+/);
        if (match) {
          let pathPostfix = location.pathname.substring(match[0].length);          
          navigate(`${urlPrefix}${pathPostfix}`);
        } else {
          let matchPcOnly = location.pathname.match(/\/pc\/[^/]+\//);
          if (matchPcOnly) {
            let pathPostfix = location.pathname.substring(matchPcOnly[0].length);
            console.log(pathPostfix);
            navigate(`/pc/${selectedPc.urlId}/${pathPostfix}`);
          }
        }
      }
    }
  }, [urlPrefix]);

  const toggleNavbar = () => {    
    setCollapsed(!collapsed);
  };

  const onTermClick = (term) => {
    setSelectedTerm(term);    
  };

  const onPlaycentreClick = (pc) => {
    setSelectedPc(pc);
  };

  const onLogoutClick = async (event) => {
    event.preventDefault();
    AuthService.logout();
  };

  return (
    <header>     
      <Navbar className={`${styles.navigationHeader} navbar-expand-sm ng-white border-bottom box-shadow mb-3`} container light>
        <NavbarBrand tag={'div'}>
          <Nav className="m1e-auto" navbar>
            {playcentres && selectedPc &&
              <>                
                <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {selectedPc?.displayName}
                  </DropdownToggle>
                  <DropdownMenu end>
                    {
                      playcentres.map(pc => (<DropdownItem key={pc.uid} onClick={() => onPlaycentreClick(pc)}>{pc.displayName}</DropdownItem>))
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            }
          </Nav>

        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex" isOpen={!collapsed} navbar>
          <Nav className="me-auto" navbar>
            {terms && selectedTerm &&
              <>                
                <NavLink className="align-self-center ms-5">Term:</NavLink>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    {selectedTerm.displayName}
                  </DropdownToggle>
                  <DropdownMenu end>
                    {
                      terms.map(term => (<DropdownItem key={term.uid} onClick={() => onTermClick(term)}>{term.displayName}</DropdownItem>))
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            }
          </Nav>
          
          <ul className="navbar-nav flex-grow align-items-center">
            {selectedTerm &&
              <>            
                <NavLink tag={Link} className="text-dark" to={`${urlPrefix}/help`}><Icon.QuestionCircle className="text-primary me-3" size="1.5rem" /></NavLink>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Roster
                  </DropdownToggle>
                  <DropdownMenu end>
                  <DropdownItem tag={Link} className="text-dark" to={`${urlPrefix}/roster`}>View roster for {selectedTerm.displayName}</DropdownItem>
                  {selectedPc?.permissions?.canEditRoster &&
                    <DropdownItem tag={Link} className="text-dark" to={`${urlPrefix}/roster/edit`}>Edit roster for {selectedTerm.displayName}</DropdownItem>
                  }
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Members
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem tag={Link} className="text-dark" to={`${urlPrefix}/members/list`}>Contact list</DropdownItem>
                    <DropdownItem tag={Link} className="text-dark" to={`${urlPrefix}/members/supervision`}>Centre supervision plan</DropdownItem>
                    <DropdownItem tag={Link} className="text-dark" to={`${urlPrefix}/members/staff_details`}>Staff details</DropdownItem>
                    <DropdownItem tag={Link} className="text-dark" to={`${urlPrefix}/members/days_change`}>Days change report</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag={Button} className="text-dark" onClick={() => fileDownload(`${urlPrefix}/members/change_of_days/download`)}>Download 'Change of days of enrolment' for {selectedTerm.displayName}</DropdownItem>
                    <DropdownItem tag={Button} className="text-dark" onClick={() => fileDownload(`${urlPrefix}/members/supervision_plan/download`)}>Download 'Supervision plan' for {selectedTerm.displayName}</DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem tag={Link} className="text-dark" to={`/pc/${selectedPc?.urlId}/settings/members/list`}>Manage playcentre members</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Kids
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem tag={Link} className="text-dark" to={`${urlPrefix}/kids/enrolment`}>Enrolment report</DropdownItem>
                    <DropdownItem tag={Link} className="text-dark" to={`${urlPrefix}/kids/days_of_weeks`}>Children days</DropdownItem>
                    {/*<DropdownItem>Children birthdays</DropdownItem>*/}
                    <DropdownItem divider />
                    <DropdownItem tag={Button} className="text-dark" onClick={() => fileDownload(`${urlPrefix}/kids/daybook/download`)}>Download 'Day book' for {selectedTerm.displayName}</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            }
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <CommonIcons.Account className={styles.accountIcon} />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag={Link} className="text-dark" disabled>{AuthService.getLoggedInUser()?.displayName}</DropdownItem>
                <DropdownItem divider />
                {/*<DropdownItem tag={Link} className="text-dark" to={`/profile`}>Profile</DropdownItem>*/}
                <DropdownItem tag={Button} className="text-dark" onClick={onLogoutClick}>Logout</DropdownItem>
              </DropdownMenu>
             
            </UncontrolledDropdown>
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*  <NavLink tag={Link} className="text-dark" to="/kids/list">Kids by day</NavLink>*/}
            { /*Kids days with colors and columns - name of the day*/}
            {/*</NavItem>*/}            
            {/*<NavItem>*/}
            {/*  <NavLink tag={Link} className="text-dark" to="/">Child details</NavLink>*/}
            { /* Chils, age with month, days sorded by age desc, with days: 9.15-11.45 or empty */}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*  <NavLink tag={Link} className="text-dark" to="/">Children birthdays</NavLink>*/}
            { /* kids split in groups of full years order by birthday */}
            {/* </NavItem>*/}
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
}

